import * as React from "react";
import { memo } from "react";
const SvgLocationWithStarGray = (props) => /* @__PURE__ */ React.createElement(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: 19,
    height: 22,
    fill: "none",
    ...props
  },
  /* @__PURE__ */ React.createElement("g", { clipPath: "url(#bd68725d-9e0f-469c-9af3-56b5c31d973f-a)" }, /* @__PURE__ */ React.createElement(
    "path",
    {
      stroke: "#828282",
      strokeWidth: 2,
      d: "M17.86 9c0 5.18-7.16 12-8.18 12S1.5 14.18 1.5 9c0-4.42 3.66-8 8.18-8a8.1 8.1 0 0 1 8.18 8Z"
    }
  ), /* @__PURE__ */ React.createElement(
    "path",
    {
      fill: "#828282",
      d: "m9.8 5.37.85 2.62q.04.08.12.09h2.75c.12 0 .17.16.07.23l-2.22 1.62s-.06.09-.05.14l.85 2.62c.04.12-.1.21-.19.14l-2.22-1.62s-.1-.03-.15 0l-2.22 1.62c-.1.07-.23-.02-.19-.14l.85-2.62A.1.1 0 0 0 8 9.93L5.78 8.31c-.1-.07-.05-.23.07-.23H8.6q.08 0 .12-.09l.85-2.62c.04-.12.2-.12.24 0z"
    }
  )),
  /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "bd68725d-9e0f-469c-9af3-56b5c31d973f-a" }, /* @__PURE__ */ React.createElement("path", { fill: "#fff", d: "M.5 0h18.36v22H.5z" })))
);
const Memo = memo(SvgLocationWithStarGray);
export default Memo;
